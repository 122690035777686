import { observer } from "mobx-react";
import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import React, { FC, useMemo } from "react";
import { LeadSyncMRIRadarButton } from "./LeadMRIRadarSync/LeadSyncMRIRadarButton";
import { LeadSyncOpenViewButton } from "./LeadOpenViewSync/LeadSyncOpenViewButton";
import { isApplicationProductOnSpecificPlan } from "utils/account-billing.utils";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { ApplicationProductPlanEnum } from "enums/application-product-plan.enum";
import { LeadSyncReapitButton } from "./LeadReapitSync/LeadSyncReapitButton";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { LeadUrlSyncButton } from "./LeadUrlSync/LeadUrlSyncButton";
import { LeadSyncAltoButton } from "./LeadAltoSync/LeadSyncAltoButton";
import { LeadSyncSmeButton } from "./LeadSmeSync/LeadSyncSmeButton";

interface IProps {
  accountStore: UserAccountStore;
}

const singleButtonStyle = {
  borderRadius: "full",
};

const multiButtonStyle = {
  borderLeftRadius: "full",
};

export const LeadDrawerIntegrationsDropdown: FC<IProps> = observer(
  ({ accountStore }) => {
    const {
      accountLeadsStore: { selectedLeadStore },
    } = accountStore;

    const accountBilling = accountStore.accountBillingStore?.billing;
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const hasActiveUrlSyncIntegration =
      accountIntegrationsStore.hasActiveUrlSyncIntegration;
    const hasActiveMRIRadarIntegration =
      accountIntegrationsStore.hasActiveMRIRadarIntegration;
    const hasActiveOpenViewIntegration =
      accountIntegrationsStore.hasActiveOpenViewIntegration;
    const hasActiveReapitIntegration =
      accountIntegrationsStore.hasActiveReapitIntegration;
    const hasActiveAltoIntegration =
      accountIntegrationsStore.hasActiveManualAltoIntegration;
    const hasActiveSmeIntegration =
      accountIntegrationsStore.hasActiveSmeIntegration;
    const leadData = selectedLeadStore?.lead;
    const isLeadDeleted = selectedLeadStore?.isLeadDeleted;

    const syncButtons = useMemo(() => {
      const buttons: React.ReactNode[] = [];

      if (!leadData || isLeadDeleted) return buttons;

      if (hasActiveMRIRadarIntegration) {
        buttons.push(
          <LeadSyncMRIRadarButton
            leadStore={selectedLeadStore}
            hasActiveMRIRadarIntegration={hasActiveMRIRadarIntegration}
          />
        );
      }

      if (hasActiveOpenViewIntegration) {
        buttons.push(
          <LeadSyncOpenViewButton
            leadStore={selectedLeadStore}
            hasActiveOpenViewIntegration={hasActiveOpenViewIntegration}
            accountStore={accountStore}
          />
        );
      }

      if (hasActiveUrlSyncIntegration) {
        buttons.push(
          <LeadUrlSyncButton
            leadStore={selectedLeadStore}
            hasActiveUrlSyncIntegration={hasActiveUrlSyncIntegration}
          />
        );
      }

      if (hasActiveAltoIntegration) {
        buttons.push(
          <LeadSyncAltoButton
            leadStore={selectedLeadStore}
            hasActiveAltoIntegration={hasActiveAltoIntegration}
          />
        );
      }

      if (hasActiveSmeIntegration) {
        buttons.push(
          <LeadSyncSmeButton
            leadStore={selectedLeadStore}
            hasActiveSmeIntegration={hasActiveSmeIntegration}
          />
        );
      }

      if (
        hasActiveReapitIntegration &&
        isApplicationProductOnSpecificPlan(
          ApplicationProductEnum.REAPIT_INTEGRATION,
          [ApplicationProductPlanEnum.PAID, ApplicationProductPlanEnum.CUSTOM],
          accountBilling
        )
      ) {
        buttons.push(
          <LeadSyncReapitButton
            leadStore={selectedLeadStore}
            hasActiveReapitIntegration={hasActiveReapitIntegration}
            accountStore={accountStore}
          />
        );
      }

      return buttons;
    }, [
      hasActiveMRIRadarIntegration,
      hasActiveOpenViewIntegration,
      hasActiveReapitIntegration,
      hasActiveUrlSyncIntegration,
      hasActiveAltoIntegration,
      hasActiveSmeIntegration,
      isLeadDeleted,
      leadData,
      selectedLeadStore,
      accountBilling,
      accountStore,
    ]);

    if (!syncButtons.length) return null;

    return (
      <Flex border={"2px solid #E0E0E0"} borderRadius={"full"}>
        {React.cloneElement(syncButtons[0] as React.ReactElement, {
          styles: syncButtons.length > 1 ? multiButtonStyle : singleButtonStyle,
        })}
        {syncButtons.length > 1 && (
          <Popover>
            <PopoverTrigger>
              <Button
                variant={"ghost"}
                borderRightRadius={"full"}
                borderLeft={"2px solid #E0E0E0"}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </Button>
            </PopoverTrigger>
            <PopoverContent minWidth={"200px"} width={"100%"}>
              <PopoverArrow />
              <PopoverBody>
                {syncButtons.slice(1).map((button, index) => (
                  <React.Fragment key={index}>{button}</React.Fragment>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
    );
  }
);
