import { TLeadWithDetails } from "types/lead.type";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { dummyLeadDetailsData } from "constants/dummyLeadData";
import { AppStore } from "store/App.store";
import { LeadsApi } from "api/leads.api";
import { TAssignableToLeadUserData } from "types/user-data.type";
import { keyBy, sortBy } from "lodash";
import { getUserFullName } from "utils/account-user.utils";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { TLeadReapitSyncData } from "types/reapit.type";
import { AccountLeadActivitiesStore } from "./AccountLeadActivities.store";
import { TLeadOpenViewSyncData } from "types/openview.type";
import { AccountLeadAttributionsStore } from "./AccountLeadAttributions.store";
import { TLeadAltoAddress } from "types/alto.type";
import { TSelectOption } from "types/select-input.type";
import { TLeadSmeSyncData } from "types/sme.type";

const leadsApi = new LeadsApi();
const REAPIT_SYNC_SUPPORTED_LEAD_TYPES = [
  LeadTypeEnum.Sale,
  LeadTypeEnum.Let,
  LeadTypeEnum.Vendor,
  LeadTypeEnum.Landlord,
];
const OPEN_VIEW_SYNC_SUPPORTED_LEAD_TYPES = [
  LeadTypeEnum.Sale,
  LeadTypeEnum.Let,
  LeadTypeEnum.Landlord,
  LeadTypeEnum.Vendor,
];

const ALTO_SYNC_SUPPORTED_LEAD_TYPES = [
  LeadTypeEnum.Sale,
  LeadTypeEnum.Let,
  LeadTypeEnum.Landlord,
  LeadTypeEnum.Vendor,
];

const SME_SYNC_SUPPORTED_LEAD_TYPES = [
  LeadTypeEnum.Sale,
  LeadTypeEnum.Let,
  LeadTypeEnum.Landlord,
  LeadTypeEnum.Vendor,
];

let latestLeadDetailsLoadingAttempt: number | null;

export class AccountLeadStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly assignableUsers: IObservableArray<TAssignableToLeadUserData>;
  private leadData: TLeadWithDetails;
  public leadActivitiesStore: AccountLeadActivitiesStore;
  public leadAttributionsStore: AccountLeadAttributionsStore;

  constructor(root: AppStore, accountId: number, data: TLeadWithDetails) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.assignableUsers = observable.array<TAssignableToLeadUserData>();
    this.leadData = data;
    this.leadActivitiesStore = new AccountLeadActivitiesStore(
      root,
      accountId,
      data.id
    );
    this.leadAttributionsStore = new AccountLeadAttributionsStore(
      root,
      accountId,
      data.id
    );
  }

  get lead() {
    return this.leadData;
  }

  get assignableUsersMap() {
    return keyBy(this.assignableUsers, user => user.id);
  }

  get assignableUsersOptionsArray(): TSelectOption<
    TAssignableToLeadUserData
  >[] {
    return sortBy(this.assignableUsers, user =>
      getUserFullName(user.firstName, user.lastName)
    ).map<TSelectOption<TAssignableToLeadUserData>>(user => {
      return {
        value: user,
        label: user.email,
        isDisabled: user.shouldDisable,
        isHidden: user.shouldHide,
      };
    });
  }

  get isLeadDeleted() {
    const deletedStatus = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ].accountLeadStatusesStore.deletedStatus;
    return this.leadData.statusId === deletedStatus?.id;
  }

  get isLeadSyncedToReapit() {
    const leadData = this.leadData;
    const reapitData = leadData.reapitData;

    return !!reapitData?.reapitJournalEntryId;
  }

  get isLeadSyncedToMRIRadar() {
    const leadData = this.leadData;
    const mriRadarData = leadData.mriRadarData;

    return !!mriRadarData?.leadId;
  }

  get isLeadSyncedToUrlSync() {
    const leadData = this.leadData;
    const urlData = leadData.urlSyncData;
    return !!urlData?.url;
  }

  get isLeadSyncedToAlto() {
    const leadData = this.leadData;
    const altoData = leadData.altoSyncData;
    return !!altoData?.leadId;
  }

  get isLeadSyncedToOpenView() {
    const leadData = this.leadData;
    const openViewData = leadData.openViewData;

    return !!openViewData?.leadId;
  }

  get isLeadSyncedToSme() {
    const leadData = this.leadData;
    const smeData = leadData.smeData;

    return !!smeData?.leadId;
  }

  get isReapitSyncAllowedForLeadType() {
    return REAPIT_SYNC_SUPPORTED_LEAD_TYPES.includes(this.leadData.type);
  }

  get isOpenViewSyncAllowedForLeadType() {
    return OPEN_VIEW_SYNC_SUPPORTED_LEAD_TYPES.includes(this.leadData.type);
  }

  get isSmeSyncAllowedForLeadType() {
    return SME_SYNC_SUPPORTED_LEAD_TYPES.includes(this.leadData.type);
  }

  get isAltoSyncAllowedForLeadType() {
    return ALTO_SYNC_SUPPORTED_LEAD_TYPES.includes(this.leadData.type);
  }

  get isLeadAssignedToUser() {
    return !!this.leadData.assignedToId;
  }

  get isLeadAssignedToOffice() {
    return !!this.leadData.officeId;
  }

  get isChildLead() {
    return !this.leadData.isParent;
  }

  get isLeadReferred() {
    return (
      !!this.leadData.leadReferredByAccount ||
      !!this.leadData.leadReferredByOffice
    );
  }

  get isLeadOfficeSyncedWithReapit() {
    if (!this.leadData.officeId) return false;

    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const reapitOfficesRelations =
      accountStore.accountIntegrationsStore.accountIntegrationReapitStore
        .accountIntegrationReapitOfficesStore
        .officeRelationsMapByLeadProOfficeId;

    return !!reapitOfficesRelations[this.leadData.officeId];
  }

  get isLeadOfficeSyncedWithOpenView() {
    if (!this.leadData.officeId) return false;

    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const openViewOfficesRelations =
      accountStore.accountIntegrationsStore.accountIntegrationOpenViewStore
        .accountIntegrationOpenViewOfficesStore
        .officeRelationsMapByLeadProOfficeId;

    return !!openViewOfficesRelations[this.leadData.officeId];
  }

  get isLeadOfficeSyncedWithAlto() {
    if (!this.leadData.officeId) return false;

    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const altoOfficesRelations =
      accountStore.accountIntegrationsStore.accountIntegrationAltoStore
        .accountIntegrationAltoOfficesStore.officeRelationsMapByLeadProOfficeId;

    return !!altoOfficesRelations[this.leadData.officeId];
  }

  private setAssignableUsers(assignableUsers: TAssignableToLeadUserData[]) {
    this.assignableUsers.replace(assignableUsers);
  }

  public upsertLeadData(lead: Partial<TLeadWithDetails>) {
    this.leadData = {
      ...this.leadData,
      ...lead,
    };
  }

  public async fetchDetails() {
    try {
      const now = Date.now();
      latestLeadDetailsLoadingAttempt = now;

      const isDemoModeOn = this.root.uiStore.isDemoModeOn;
      if (isDemoModeOn) {
        this.upsertLeadData(dummyLeadDetailsData);
      } else if (now === latestLeadDetailsLoadingAttempt) {
        const accountStore = this.root.userAccountsStore.userAccountsMap[
          this.accountId
        ];

        let leadData = await leadsApi.getLeadWithDetails(
          this.accountId,
          this.leadData.id
        );

        try {
          const promises: Promise<Partial<TLeadWithDetails>>[] = [];
          if (
            accountStore?.accountIntegrationsStore.hasActiveReapitIntegration
          ) {
            promises.push(
              leadsApi.getLeadReapitData(this.accountId, this.leadData.id)
            );
          }
          if (
            accountStore?.accountIntegrationsStore.hasActiveMRIRadarIntegration
          ) {
            promises.push(
              leadsApi.getLeadMRIRadarData(this.accountId, this.leadData.id)
            );
          }
          if (
            accountStore?.accountIntegrationsStore.hasActiveOpenViewIntegration
          ) {
            promises.push(
              leadsApi.getLeadOpenViewData(this.accountId, this.leadData.id)
            );
            if (
              accountStore?.accountIntegrationsStore
                .hasActiveManualAltoIntegration
            ) {
              promises.push(
                leadsApi.getLeadAltoSyncData(this.accountId, this.leadData.id)
              );
            }
            if (
              accountStore?.accountIntegrationsStore.hasActiveUrlSyncIntegration
            ) {
              promises.push(
                leadsApi.getLeadUrlSyncData(this.accountId, this.leadData.id)
              );
            }
            if (
              accountStore?.accountIntegrationsStore.hasActiveSmeIntegration
            ) {
              promises.push(
                leadsApi.getLeadSmeData(this.accountId, this.leadData.id)
              );
            }
          }
          if (!!promises.length) {
            const additionalData = await Promise.all(promises);
            additionalData.forEach(
              leadDataSet => (leadData = { ...leadData, ...leadDataSet })
            );
          }
        } catch (error) {
        } finally {
          this.upsertLeadData(leadData);
        }
      }
    } catch (error) {}
  }

  public async update(data: Partial<TLeadWithDetails>) {
    const updatedLead = await leadsApi.updateLead(
      this.accountId,
      this.leadData.id,
      data
    );
    this.upsertLeadData(updatedLead);
  }

  public async addLeadContactNote(
    content: string,
    options: { append?: boolean } = { append: true }
  ) {
    const note = await leadsApi.addLeadContactNote(
      this.accountId,
      this.leadData.person.id,
      content
    );

    if (!options.append) return;
    this.leadActivitiesStore.appendNewNoteToActivities(note);
  }

  public async fetchAssignableUsers() {
    const isDemoModeOn = this.root.uiStore.isDemoModeOn;

    if (!isDemoModeOn) {
      const assignableUsersForSingleLead = await leadsApi.fetchAssignableUsersForSingleLead(
        this.accountId,
        this.leadData.id
      );

      this.setAssignableUsers(assignableUsersForSingleLead);
    }
  }

  public async syncLeadWithReapit(syncData: TLeadReapitSyncData) {
    const data = await leadsApi.syncLeadWithReapit(
      this.accountId,
      this.leadData.id,
      syncData
    );
    this.upsertLeadData(data);
  }

  public async syncLeadWithMRIRadar() {
    const data = await leadsApi.syncLeadWithMRIRadar(
      this.accountId,
      this.leadData.id
    );
    this.upsertLeadData(data);
  }

  public async syncLeadWithOpenView(syncData: TLeadOpenViewSyncData) {
    const data = await leadsApi.syncLeadWithOpenView(
      this.accountId,
      this.leadData.id,
      syncData
    );
    this.upsertLeadData(data);
  }

  public async syncLeadWithSme(syncData: TLeadSmeSyncData) {
    const data = await leadsApi.syncLeadWithSme(
      this.accountId,
      this.leadData.id,
      syncData
    );
    this.upsertLeadData(data);
  }

  public async syncLeadWithAlto(address: TLeadAltoAddress) {
    const data = await leadsApi.syncLeadWithAlto(
      this.accountId,
      this.leadData.id,
      address
    );
    this.upsertLeadData(data);
  }

  public async syncLeadWithUrl() {
    const data = await leadsApi.syncLeadWithUrl(
      this.accountId,
      this.leadData.id
    );
    this.upsertLeadData(data);
    data.urlSyncData?.url && window.open(data.urlSyncData.url, "_blank");
  }
}
